.other h2 {
   font-size: 18px;
   color: #444;
   margin-bottom: 7px;
}

.other a {
   color: #777;
   text-decoration: underline;
   font-size: 14px;
}

.other ul {
   padding: 0;
   margin: 0;
   list-style-type: none;
}

h1 {
   font-size: 24px;
   color: #333;
}

.ltr {
   text-align: left;
}

.rtl {
   text-align: right;
}

.editor-shell {
   margin: 20px auto;
   border-radius: 2px;
   /*max-width: 1100px;*/
   color: #000;
   position: relative;
   line-height: 1.7;
   font-weight: 400;
}
   
.editor-scroller {
   /*min-height: 150px;*/
   border: 0;
   display: flex;
   position: relative;
   outline: 0;
   z-index: 0;
   overflow: auto;
   /*resize: vertical;*/
   resize: none;
}

.editor {
   flex: auto;
   position: relative;
   /*resize: vertical;*/
   resize: none;
   z-index: -1;
}

.editor-shell .editor-container {
   background: #fff;
   position: relative;
   display: block;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}

.editor-container {
   margin: 0 auto;
   color: #000;
   position: relative;
   line-height: 20px;
   font-weight: 400;
   text-align: left;
   border: 1px solid #ccc;
   /*min-height: 100%;*/
}

.editor-inner {
   background: #fff;
   position: relative;
   border-top: 1px solid #ccc;
}

.editor-input {
   min-height: 150px;
   resize: none;
   font-size: 16px;
   caret-color: rgb(5, 5, 5);
   position: relative;
   tab-size: 1;
   outline: 0;
   padding: 15px 10px;
   caret-color: #444;
}

.editor-placeholder {
   color: #999;
   overflow: hidden;
   position: absolute;
   text-overflow: ellipsis;
   top: 10px;
   left: 10px;
   font-size: 16px;
   user-select: none;
   display: inline-block;
   pointer-events: none;
}

.editor-text-bold {
   font-weight: bold;
}

.editor-text-italic {
   font-style: italic;
}

.editor-text-underline {
   text-decoration: underline;
}

.editor-text-strikethrough {
   text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
   text-decoration: underline line-through;
}

.editor-text-code {
   background-color: rgb(240, 242, 245);
   padding: 1px 0.25rem;
   font-family: Menlo, Consolas, Monaco, monospace;
   font-size: 94%;
}

.editor-link {
   color: rgb(33, 111, 219);
   text-decoration: none;
}

.tree-view-output {
   display: block;
   background: #222;
   color: #fff;
   padding: 5px;
   font-size: 12px;
   white-space: pre-wrap;
   margin: 1px auto 10px auto;
   max-height: 250px;
   position: relative;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
   overflow: auto;
   line-height: 14px;
}

.editor-code {
   background-color: rgb(240, 242, 245);
   font-family: Menlo, Consolas, Monaco, monospace;
   display: block;
   padding: 8px 8px 8px 52px;
   line-height: 1.53;
   font-size: 13px;
   margin: 0;
   margin-top: 8px;
   margin-bottom: 8px;
   tab-size: 2;
   /* white-space: pre; */
   overflow-x: auto;
   position: relative;
}

.editor-code:before {
   content: attr(data-gutter);
   position: absolute;
   background-color: #eee;
   left: 0;
   top: 0;
   border-right: 1px solid #ccc;
   padding: 8px;
   color: #777;
   white-space: pre-wrap;
   text-align: right;
   min-width: 25px;
}

.editor-code:after {
   content: attr(data-highlight-language);
   top: 0;
   right: 3px;
   padding: 3px;
   font-size: 10px;
   text-transform: uppercase;
   position: absolute;
   color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
   color: slategray;
}

.editor-tokenPunctuation {
   color: #999;
}

.editor-tokenProperty {
   color: #905;
}

.editor-tokenSelector {
   color: #690;
}

.editor-tokenOperator {
   color: #9a6e3a;
}

.editor-tokenAttr {
   color: #07a;
}

.editor-tokenVariable {
   color: #e90;
}

.editor-tokenFunction {
   color: #dd4a68;
}

.editor-paragraph {
    font-family: 'VremenaGroteskBook';
    color: #041234;
    margin: 0;
    margin-bottom: 8px;
    position: relative;
}

.editor-paragraph:last-child {
   margin-bottom: 0;
}

.editor-heading-h1 {
   font-size: 24px;
   color: rgb(5, 5, 5);
   font-weight: 400;
   margin: 0;
   margin-bottom: 12px;
   padding: 0;
}

.editor-heading-h2 {
   font-size: 20px;
   color: rgb(101, 103, 107);
   font-weight: 700;
   margin: 0;
   margin-top: 10px;
   padding: 0;
   text-transform: uppercase;
}

.editor-quote {
   margin: 0;
   margin-left: 20px;
   font-size: 16px;
   color: rgb(101, 103, 107);
   border-left-color: rgb(206, 208, 212);
   border-left-width: 4px;
   border-left-style: solid;
   padding-left: 16px;
}

.editor-list-ol {
   list-style-type: decimal;
   padding: 0;
   margin: 0;
   margin-left: 16px;
}

.editor-list-ul {
   list-style-type: disc;
   padding: 0;
   margin: 0;
   margin-left: 16px;
}

.editor-listitem {
   margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
   list-style-type: none;
}

pre::-webkit-scrollbar {
   background: transparent;
   width: 10px;
}

pre::-webkit-scrollbar-thumb {
   background: #999;
}

.debug-timetravel-panel {
   overflow: hidden;
   padding: 0 0 10px 0;
   margin: auto;
   display: flex;
}

.debug-timetravel-panel-slider {
   padding: 0;
   flex: 8;
}

.debug-timetravel-panel-button {
   padding: 0;
   border: 0;
   background: none;
   flex: 1;
   color: #fff;
   font-size: 12px;
}

.debug-timetravel-panel-button:hover {
   text-decoration: underline;
}

.debug-timetravel-button {
   border: 0;
   padding: 0;
   font-size: 12px;
   top: 10px;
   right: 15px;
   position: absolute;
   background: none;
   color: #fff;
}

.debug-timetravel-button:hover {
   text-decoration: underline;
}

.toolbar {
   display: flex;
   margin-bottom: 1px;
   background: #f9fafb;
   padding: 4px;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   vertical-align: middle;
}

.toolbar button.toolbar-item {
   border: 0;
   display: flex;
   background: none;
   border-radius: 10px;
   padding: 8px;
   cursor: pointer;
   vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
   cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
   margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
   background-size: contain;
   display: inline-block;
   height: 18px;
   width: 18px;
   margin-top: 2px;
   vertical-align: -0.25em;
   display: flex;
   opacity: 1;
}

.toolbar button.toolbar-item:disabled i.format {
   opacity: 0.2;
}

.toolbar button.toolbar-item.active {
   background-color: #E0E0E0;
}

.toolbar button.toolbar-item.active i {
   opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
   background-color: #E0E0E0;
}

.toolbar .divider {
   width: 1px;
   background-color: #E0E0E0;
   margin: 0 4px;
   flex-shrink: 0;
}

.toolbar .toolbar-item .text {
   display: flex;
   line-height: 20px;
   width: 200px;
   vertical-align: middle;
   font-size: 14px;
   color: #777;
   text-overflow: ellipsis;
   width: 70px;
   overflow: hidden;
   height: 20px;
   text-align: left;
}

.toolbar .toolbar-item .icon {
   display: flex;
   width: 20px;
   height: 20px;
   user-select: none;
   margin-right: 8px;
   line-height: 16px;
   background-size: contain;
}

.dropdown {
   z-index: 100;
   display: block;
   position: fixed;
   box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
     inset 0 0 0 1px rgba(255, 255, 255, 0.5);
   border-radius: 8px;
   min-height: 40px;
   background-color: #fff;
}
 
.dropdown .item {
   margin: 0 8px 0 8px;
   padding: 8px;
   color: #050505;
   cursor: pointer;
   line-height: 16px;
   font-size: 15px;
   display: flex;
   align-content: center;
   flex-direction: row;
   flex-shrink: 0;
   justify-content: space-between;
   background-color: #fff;
   border-radius: 8px;
   border: 0;
   max-width: 250px;
   min-width: 100px;
}
 
.dropdown .item.fontsize-item,
.dropdown .item.fontsize-item .text {
   min-width: unset;
}
 
.dropdown .item .active {
   display: flex;
   width: 20px;
   height: 20px;
   background-size: contain;
}
 
.dropdown .item:first-child {
   margin-top: 8px;
}
 
.dropdown .item:last-child {
   margin-bottom: 8px;
}
 
.dropdown .item:hover {
   background-color: #eee;
}
 
.dropdown .item .text {
   display: flex;
   line-height: 20px;
   flex-grow: 1;
   min-width: 150px;
}
 
.dropdown .item .icon {
   display: flex;
   width: 20px;
   height: 20px;
   user-select: none;
   margin-right: 12px;
   line-height: 16px;
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
}
 
.dropdown .divider {
   width: auto;
   background-color: #eee;
   margin: 4px 8px;
   height: 1px;
}
 
@media screen and (max-width: 1100px) {
   .dropdown-button-text {
      display: none !important;
   }
 
   .dialog-dropdown > .dropdown-button-text {
      display: flex !important;
   }
 
   .font-size .dropdown-button-text {
      display: flex !important;
   }
 
   .code-language .dropdown-button-text {
      display: flex !important;
   }
}

i.undo {
   background-image: url(/src/images/lexical/arrow-counterclockwise.svg);
}

i.redo {
   background-image: url(/src/images/lexical/arrow-clockwise.svg);
}

i.bold {
   background-image: url(/src/images/lexical/type-bold.svg);
}

i.italic {
   background-image: url(/src/images/lexical/type-italic.svg);
}

i.underline {
   background-image: url(/src/images/lexical/type-underline.svg);
}

i.strikethrough {
   background-image: url(/src/images/lexical/type-strikethrough.svg);
}

i.subscript {
   background-image: url(/src/images/lexical/type-subscript.svg);
}

i.superscript {
   background-image: url(/src/images/lexical/type-superscript.svg);
}

i.left-align {
   background-image: url(/src/images/lexical/text-left.svg);
}

i.center-align {
   background-image: url(/src/images/lexical/text-center.svg);
}

i.right-align {
   background-image: url(/src/images/lexical/text-right.svg);
}

i.justify-align {
   background-image: url(/src/images/lexical/justify.svg);
}

i.indent {
   background-image: url(/src/images/lexical/indent.svg);
}

i.outdent {
   background-image: url(/src/images/lexical/outdent.svg);
}

i.paragraph {
   background-image: url(/src/images/lexical/text-paragraph.svg);
}

i.h1 {
   background-image: url(/src/images/lexical/type-h1.svg);
}

i.h2 {
   background-image: url(/src/images/lexical/type-h2.svg);
}

i.h3 {
   background-image: url(/src/images/lexical/type-h3.svg);
}

i.h4 {
   background-image: url(/src/images/lexical/type-h4.svg);
}

i.h5 {
   background-image: url(/src/images/lexical/type-h5.svg);
}

i.h6 {
   background-image: url(/src/images/lexical/type-h6.svg);
}

i.bullet-list {
   background-image: url(/src/images/lexical/list-ul.svg);
}

i.numbered-list {
   background-image: url(/src/images/lexical/list-ol.svg);
}

i.check-list {
   background-image: url(/src/images/lexical/square-check.svg);
}

i.quote {
   background-image: url(/src/images/lexical/chat-square-quote.svg);
}

i.dropdown-more {
   background-image: url(/src/images/lexical/dropdown-more.svg);
}

i.link {
   background-image: url(/src/images/lexical/link.svg);
}

i.plus {
   background-image: url(/src/images/lexical/plus.svg);
}

i.plus-minus {
    background-image: url(/src/images/lexical/plus-slash-minus.svg);
}
 
i.font-color {
   background-image: url(/src/images/lexical/font-color.svg);
}

i.bg-color {
   background-image: url(/src/images/lexical/bg-color.svg);
}

i.horizontal-rule {
   background-image: url(/src/images/lexical/horizontal-rule.svg);
}

i.image {
   background-image: url(/src/images/lexical/file-image.svg);
}

i.table {
   background-image: url(/src/images/lexical/table.svg);
}

i.columns {
   background-image: url(/src/images/lexical/3-columns.svg);
}

i.youtube {
   background-image: url(/src/images/lexical/youtube.svg);
}

i.clear {
   background-image: url(/src/images/lexical/trash.svg);
}

.link-editor .button.active,
.toolbar .button.active {
   background-color: rgb(223, 232, 250);
}

.link-editor .link-input {
   display: block;
   width: calc(100% - 75px);
   box-sizing: border-box;
   margin: 12px 12px;
   padding: 8px 12px;
   border-radius: 15px;
   background-color: #eee;
   font-size: 15px;
   color: rgb(5, 5, 5);
   border: 0;
   outline: 0;
   position: relative;
   font-family: inherit;
}

.link-editor .link-view {
   display: block;
   width: calc(100% - 24px);
   margin: 8px 12px;
   padding: 8px 12px;
   border-radius: 15px;
   font-size: 15px;
   color: rgb(5, 5, 5);
   border: 0;
   outline: 0;
   position: relative;
   font-family: inherit;
}

.link-editor .link-view a {
   display: block;
   word-break: break-word;
   width: calc(100% - 33px);
}

.link-editor div.link-edit {
   background-image: url(/src/images/lexical/pencil-fill.svg);
   background-size: 16px;
   background-position: center;
   background-repeat: no-repeat;
   width: 35px;
   vertical-align: -0.25em;
   position: absolute;
   right: 30px;
   top: 0;
   bottom: 0;
   cursor: pointer;
}

.link-editor div.link-trash {
   background-image: url(/src/images/lexical/trash.svg);
   background-size: 16px;
   background-position: center;
   background-repeat: no-repeat;
   width: 35px;
   vertical-align: -0.25em;
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   cursor: pointer;
}

.link-editor div.link-cancel {
   background-image: url(/src/images/lexical/close.svg);
   background-size: 16px;
   background-position: center;
   background-repeat: no-repeat;
   width: 35px;
   vertical-align: -0.25em;
   margin-right: 28px;
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   cursor: pointer;
}

.link-editor div.link-confirm {
   background-image: url(/src/images/lexical/success-alt.svg);
   background-size: 16px;
   background-position: center;
   background-repeat: no-repeat;
   width: 35px;
   vertical-align: -0.25em;
   margin-right: 2px;
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   cursor: pointer;
}

.link-editor .link-input a {
   color: rgb(33, 111, 219);
   text-decoration: underline;
   white-space: nowrap;
   overflow: hidden;
   margin-right: 30px;
   text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
   text-decoration: underline;
}

.link-editor .font-size-wrapper,
.link-editor .font-family-wrapper {
   display: flex;
   margin: 0 4px;
}

.link-editor select {
   padding: 6px;
   border: none;
   background-color: rgba(0, 0, 0, 0.075);
   border-radius: 4px;
}

.editor-inner hr {
   display: block;
   height: 2px;
   background-color: #ccc;
   margin: 1em 0.5em;
}

.editor-shell span.editor-image {
   cursor: default;
   display: inline-block;
   position: relative;
   user-select: none;
}
 
.editor-shell .editor-image img {
   max-width: 100%;
   cursor: default;
}
 
.editor-shell .editor-image img.focused {
   outline: 2px solid rgb(60, 132, 244);
   user-select: none;
}
 
.editor-shell .editor-image img.focused.draggable {
   cursor: grab;
}
 
.editor-shell .editor-image img.focused.draggable:active {
   cursor: grabbing;
}
 
.editor-shell .editor-image .image-caption-container .tree-view-output {
   margin: 0;
   border-radius: 0;
}
 
.editor-shell .editor-image .image-caption-container {
   display: block;
   position: absolute;
   bottom: 4px;
   left: 0;
   right: 0;
   padding: 0;
   margin: 0;
   border-top: 1px solid #fff;
   background-color: rgba(255, 255, 255, 0.9);
   min-width: 100px;
   color: #000;
   overflow: hidden;
}
 
.editor-shell .editor-image .image-caption-button {
   display: block;
   position: absolute;
   bottom: 20px;
   left: 0;
   right: 0;
   width: 30%;
   padding: 10px;
   margin: 0 auto;
   border: 1px solid rgba(255, 255, 255, 0.3);
   border-radius: 5px;
   background-color: rgba(0, 0, 0, 0.5);
   min-width: 100px;
   color: #fff;
   cursor: pointer;
   user-select: none;
   font-size: 13px;
}
 
.editor-shell .editor-image .image-caption-button:hover {
   background-color: rgba(60, 132, 244, 0.5);
}
 
.editor-shell .editor-image .image-edit-button {
   border: 1px solid rgba(0, 0, 0, 0.3);
   border-radius: 5px;
   background-image: url(/src/images/lexical/pencil-fill.svg);
   background-size: 16px;
   background-position: center;
   background-repeat: no-repeat;
   width: 35px;
   height: 35px;
   vertical-align: -0.25em;
   position: absolute;
   right: 4px;
   top: 4px;
   cursor: pointer;
   user-select: none;
}
 
.editor-shell .editor-image .image-edit-button:hover {
   background-color: rgba(60, 132, 244, 0.1);
}
 
.editor-shell .editor-image .image-resizer {
   display: block;
   width: 9px;
   height: 9px;
   position: absolute;
   background-color: rgb(60, 132, 244);
   border: 1px solid #fff;
}
 
.editor-shell .editor-image .image-resizer.image-resizer-n {
   top: -6px;
   left: 48%;
   cursor: n-resize;
}
 
.editor-shell .editor-image .image-resizer.image-resizer-ne {
   top: -6px;
   right: -6px;
   cursor: ne-resize;
}
 
.editor-shell .editor-image .image-resizer.image-resizer-e {
   bottom: 48%;
   right: -6px;
   cursor: e-resize;
}
 
.editor-shell .editor-image .image-resizer.image-resizer-se {
   bottom: -6px;
   right: -6px;
   cursor: nwse-resize;
}
 
.editor-shell .editor-image .image-resizer.image-resizer-s {
   bottom: -6px;
   left: 48%;
   cursor: s-resize;
}
 
.editor-shell .editor-image .image-resizer.image-resizer-sw {
   bottom: -6px;
   left: -6px;
   cursor: sw-resize;
}
 
.editor-shell .editor-image .image-resizer.image-resizer-w {
   bottom: 48%;
   left: -6px;
   cursor: w-resize;
}
 
.editor-shell .editor-image .image-resizer.image-resizer-nw {
   top: -6px;
   left: -6px;
   cursor: nw-resize;
}

.editor-shell span.inline-editor-image {
   cursor: default;
   display: inline-block;
   position: relative;
   z-index: 1;
}
 
.editor-shell .inline-editor-image img {
   max-width: 100%;
   cursor: default;
}
 
.editor-shell .inline-editor-image img.focused {
   outline: 2px solid rgb(60, 132, 244);
}
 
.editor-shell .inline-editor-image img.focused.draggable {
   cursor: grab;
}
 
.editor-shell .inline-editor-image img.focused.draggable:active {
   cursor: grabbing;
}
 
.editor-shell .inline-editor-image .image-caption-container .tree-view-output {
   margin: 0;
   border-radius: 0;
}
 
.editor-shell .inline-editor-image.position-full {
   margin: 1em 0 1em 0;
}
 
.editor-shell .inline-editor-image.position-left {
   float: left;
   width: 50%;
   margin: 1em 1em 0 0;
}
 
.editor-shell .inline-editor-image.position-right {
   float: right;
   width: 50%;
   margin: 1em 0 0 1em;
}
 
.editor-shell .inline-editor-image .image-edit-button {
   display: block;
   position: absolute;
   top: 12px;
   right: 36px;
   padding: 6px 8px;
   margin: 0 auto;
   border: 1px solid rgba(255, 255, 255, 0.3);
   border-radius: 5px;
   background-color: rgba(0, 0, 0, 0.5);
   min-width: 60px;
   color: #fff;
   cursor: pointer;
   user-select: none;
}
 
.editor-shell .inline-editor-image .image-edit-button:hover {
   background-color: rgba(60, 132, 244, 0.5);
}
 
.editor-shell .inline-editor-image .image-caption-container {
   display: block;
   background-color: #f4f4f4;
   min-width: 100%;
   color: #000;
   overflow: hidden;
}

.table-cell-action-button-container {
   position: absolute;
   top: 0;
   left: 0;
   will-change: transform;
}
 
.table-cell-action-button {
   background-color: #eee;
   width: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 0;
   position: relative;
   border-radius: 15px;
   color: #222;
   display: inline-block;
   cursor: pointer;
}

.table-cell-action-button:hover {
   background-color: #ccc;
}
 
i.chevron-down {
   background-color: transparent;
   background-size: contain;
   display: inline-block;
   height: 8px;
   width: 8px;
   background-image: url(/src/images/lexical/chevron-down.svg);
}

/* Content Editable */

.ContentEditable__root {
   border: 0;
   font-size: 16px;
   display: block;
   position: relative;
   outline: 0;
   padding: 8px;
   min-height: 150px;
}
 
@media (max-width: 1025px) {
   .ContentEditable__root {
      padding-left: 8px;
      padding-right: 8px;
   }
}

/* From Playground Theme */

.LexicalTheme__table {
   border-collapse: collapse;
   border-spacing: 0;
   overflow-y: scroll;
   overflow-x: scroll;
   table-layout: fixed;
   width: max-content;
   margin: 30px 0;
}

.LexicalTheme__tableSelection *::selection {
   background-color: transparent;
}

.LexicalTheme__tableSelected {
   outline: 2px solid rgb(60, 132, 244);
}

.LexicalTheme__tableCell {
   border: 1px solid #bbb;
   width: 75px;
   min-width: 75px;
   vertical-align: top;
   text-align: start;
   padding: 6px 8px;
   position: relative;
   outline: none;
}

.LexicalTheme__tableCellSortedIndicator {
   display: block;
   opacity: 0.5;
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 4px;
   background-color: #999;
}

.LexicalTheme__tableCellResizer {
   position: absolute;
   right: -4px;
   height: 100%;
   width: 8px;
   cursor: ew-resize;
   z-index: 10;
   top: 0;
 }

.LexicalTheme__tableCellHeader {
   background-color: #f2f3f5;
   text-align: start;
}

.LexicalTheme__tableCellSelected {
   background-color: #c9dbf0;
}

.LexicalTheme__tableCellPrimarySelected {
   border: 2px solid rgb(60, 132, 244);
   display: block;
   height: calc(100% - 2px);
   position: absolute;
   width: calc(100% - 2px);
   left: -1px;
   top: -1px;
   z-index: 2;
}

.LexicalTheme__tableCellEditing {
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
   border-radius: 3px;
}

.LexicalTheme__tableAddColumns {
   position: absolute;
   top: 0;
   width: 20px;
   background-color: #eee;
   height: 100%;
   right: -25px;
   animation: table-controls 0.2s ease;
   border: 0;
   cursor: pointer;
}

.LexicalTheme__tableAddColumns:after {
   background-image: url(/src/images/lexical/plus.svg);
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
   display: block;
   content: ' ';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0.4;
}

.LexicalTheme__tableAddColumns:hover {
   background-color: #c9dbf0;
}

.LexicalTheme__tableAddRows {
   position: absolute;
   bottom: -25px;
   width: calc(100% - 25px);
   background-color: #eee;
   height: 20px;
   left: 0;
   animation: table-controls 0.2s ease;
   border: 0;
   cursor: pointer;
}

.LexicalTheme__tableAddRows:after {
   background-image: url(/src/images/lexical/plus.svg);
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
   display: block;
   content: ' ';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0.4;
}

.LexicalTheme__tableAddRows:hover {
   background-color: #c9dbf0;
}

@keyframes table-controls {
   0% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
}

.LexicalTheme__tableCellResizeRuler {
   display: block;
   position: absolute;
   width: 1px;
   background-color: rgb(60, 132, 244);
   height: 100%;
   top: 0;
}

.LexicalTheme__tableCellActionButtonContainer {
   display: block;
   right: 5px;
   top: 6px;
   position: absolute;
   z-index: 4;
   width: 20px;
   height: 20px;
}

.LexicalTheme__tableCellActionButton {
   background-color: #eee;
   display: block;
   border: 0;
   border-radius: 20px;
   width: 20px;
   height: 20px;
   color: #222;
   cursor: pointer;
}

.LexicalTheme__tableCellActionButton:hover {
   background-color: #ddd;
}

.LexicalTheme__layoutContainer {
   display: grid;
   gap: 10px;
   margin: 10px 0;
}

.LexicalTheme__layoutItem {
   border: 1px dashed #ddd;
   padding: 8px 16px;
}

.LexicalTheme__listItemChecked,
.LexicalTheme__listItemUnchecked {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 24px;
    padding-right: 24px;
    list-style-type: none;
    outline: none;
}

.LexicalTheme__listItemChecked {
    text-decoration: line-through;
}

.LexicalTheme__listItemUnchecked:before,
.LexicalTheme__listItemChecked:before {
    content: '';
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0;
    cursor: pointer;
    display: block;
    background-size: cover;
    position: absolute;
}

.LexicalTheme__listItemUnchecked[dir='rtl']:before,
.LexicalTheme__listItemChecked[dir='rtl']:before {
    left: auto;
    right: 0;
}

.LexicalTheme__listItemUnchecked:focus:before,
.LexicalTheme__listItemChecked:focus:before {
    box-shadow: 0 0 0 2px #a6cdfe;
    border-radius: 2px;
}

.LexicalTheme__listItemUnchecked:before {
    border: 1px solid #999;
    border-radius: 2px;
}

.LexicalTheme__listItemChecked:before {
    border: 1px solid rgb(61, 135, 245);
    border-radius: 2px;
    background-color: #3d87f5;
    background-repeat: no-repeat;
}

.LexicalTheme__listItemChecked:after {
    content: '';
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    display: block;
    top: 6px;
    width: 3px;
    left: 7px;
    right: 7px;
    height: 6px;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
}

.LexicalTheme__embedBlock {
    user-select: none;
}

.LexicalTheme__embedBlockFocus {
    outline: 2px solid rgb(60, 132, 244);
}
  
  