.font-size-input {
    font-weight: 400;
    font-size: 14px;
    color: #4B5163;
    border-radius: 5px;
    border-color: grey;
    height: 30px;
    padding: 2px 0 0 0;
    text-align: center;
    width: 30px;
    align-self: center;
}

.font-size-input:disabled {
    opacity: 0.2;
    cursor: not-allowed;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.add-icon {
    background-image: url(/src/images/lexical/add-sign.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.minus-icon {
    background-image: url(/src/images/lexical/minus-sign.svg);
    background-repeat: no-repeat;
    background-position: center;
}

button.font-decrement {
    padding: 0px;
    margin-right: 3px;
}

button.font-increment {
    padding: 0px;
    margin-left: 3px;
}
  