@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'VremenaGroteskBold';
  src: url('./fonts/VremenaGrotesk/VremenaGrotesk-Bold.woff2') format('woff2'),
       url('./fonts/VremenaGrotesk/VremenaGrotesk-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VremenaGroteskBoldItalic';
  src: url('./fonts/VremenaGrotesk/VremenaGrotesk-BoldItalic.woff2') format('woff2'),
       url('./fonts/VremenaGrotesk/VremenaGrotesk-BoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VremenaGroteskBook';
  src: url('./fonts/VremenaGrotesk/VremenaGrotesk-Book.woff2') format('woff2'),
       url('./fonts/VremenaGrotesk/VremenaGrotesk-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VremenaGroteskBookItalic';
  src: url('./fonts/VremenaGrotesk/VremenaGrotesk-BookItalic.woff2') format('woff2'),
       url('./fonts/VremenaGrotesk/VremenaGrotesk-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VremenaGroteskItalic';
  src: url('./fonts/VremenaGrotesk/VremenaGrotesk-Italic.woff2') format('woff2'),
       url('./fonts/VremenaGrotesk/VremenaGrotesk-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VremenaGroteskMedium';
  src: url('./fonts/VremenaGrotesk/VremenaGrotesk-Medium.woff2') format('woff2'),
       url('./fonts/VremenaGrotesk/VremenaGrotesk-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VremenaGroteskMediumItalic';
  src: url('./fonts/VremenaGrotesk/VremenaGrotesk-MediumItalic.woff2') format('woff2'),
       url('./fonts/VremenaGrotesk/VremenaGrotesk-MediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VremenaGroteskRegular';
  src: url('./fonts/VremenaGrotesk/VremenaGrotesk-Regular.woff2') format('woff2'),
       url('./fonts/VremenaGrotesk/VremenaGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'VremenaGroteskBook', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
