.Modal__overlay {
   display: flex;
   justify-content: center;
   align-items: center;
   position: fixed;
   flex-direction: column;
   top: 0px;
   bottom: 0px;
   left: 0px;
   right: 0px;
   background-color: rgba(40, 40, 40, 0.6);
   flex-grow: 0px;
   flex-shrink: 1px;
   z-index: 100;
 }

 .Modal__modal {
   padding: 20px;
   min-height: 100px;
   min-width: 300px;
   display: flex;
   flex-grow: 0px;
   background-color: #fff;
   flex-direction: column;
   position: relative;
   box-shadow: 0 0 20px 0 #444;
   border-radius: 10px;
 }

 .Modal__title {
   color: #444;
   margin: 0px;
   padding-bottom: 10px;
   border-bottom: 1px solid #ccc;
 }

 .Modal__closeButton {
   border: 0px;
   position: absolute;
   right: 20px;
   border-radius: 20px;
   justify-content: center;
   align-items: center;
   display: flex;
   width: 30px;
   height: 30px;
   text-align: center;
   cursor: pointer;
   background-color: #eee;
 }

 .Modal__closeButton:hover {
   background-color: #ddd;
 }
 
 .Modal__content {
   padding-top: 20px;
 }
 