/* App level CSS */

html {
    font-size: 16px;
}

a {
    text-decoration: underline;
    color: #041234;
}

details {
    user-select: none;
    & summary svg {
        transform: rotate(90deg);
    }
}
details[open] {
    & summary svg {
        transform: rotate(-90deg);  
    }
}

details[open] summary ~ * {	
    animation: ease-opacity-t-b .5s ease;
}

summary {
    cursor: pointer;
}

summary svg {
    transition: all 0.3s;
}

summary::-webkit-details-marker {
    display: none;
}

/* Ag-Grid Customisations */

.ag-theme-alpine.compact {
    --ag-grid-size: 4px;
    --ag-list-item-height: 24px;
}

.ag-cell-wrap-text {
    word-break: break-word;
}

body > div:last-child > span + img {
    display: inline !important;
}

/* Quill Customisations */

.ql-container {
    height: calc(100% - 42px); /* 100% - height of 'ql-toolbar' */
    background-color: #fff;
}

/* YouTube Customisation */

.youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}

/* React Layout Grid Customisation */

.react-resizable {
    background-color: rgba(26, 83, 227, 0.1);
}

.react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid rgb(26, 83, 227);
    border-bottom: 2px solid rgb(26, 83, 227);
}